<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('customer.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('customer.pageTitle'),
                        href: '/admin/customers'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <ts-panel>
                    <!-- <ts-panel-wrapper> -->
                    <form class="form-horizontal form-horizontal-text-right">
                        <form-wizard
                            :title="''"
                            :subtitle="''"
                            finishButtonText="Save"
                            color="#348fe2"
                            errorColor="red"
                            ref="wizard"
                            @on-complete="onComplete"
                        >
                            <tab-content
                                style="min-height: 460px !important;"
                                :title="$t('customer.profileInformation')"
                                icon="fas fa-user-tie"
                                :before-change="
                                    () => validateStep('profileInformation')
                                "
                            >
                                <form class="form-horizontal">
                                    <profile-information
                                        ref="profileInformation"
                                        v-model="model"
                                        :validate="errors"
                                        :clear="clear"
                                    />
                                </form>
                            </tab-content>
                            <tab-content
                                style="min-height: 460px !important;"
                                :title="$t('customer.spouse')"
                                icon="fas fa-user-friends"
                            >
                                <spouse-template
                                    ref="spouse"
                                    v-model="model"
                                    :validate="errors"
                                    :clear="clear"
                                />
                            </tab-content>
                            <tab-content
                                style="min-height: 460px !important;"
                                :title="$t('customer.guarantor')"
                                icon="fas fa-user-tag"
                            >
                                <guarantor-template
                                    v-model="model"
                                    :validate="errors"
                                    :clear="clear"
                                />
                            </tab-content>
                            <tab-content
                                style="min-height: 460px !important;"
                                :title="$t('customer.documentAttached')"
                                icon="fas fa-file-signature"
                            >
                                <document-attachment
                                    ref="documentAttachment"
                                    v-model="model"
                                    :validate="errors"
                                    :clear="clear"
                                />
                            </tab-content>

                            <template slot="footer" slot-scope="props">
                                <div class="wizard-footer-left">
                                    <wizard-button
                                        v-if="
                                            props.activeTabIndex > 0 &&
                                                !props.isLastStep
                                        "
                                        @click.native="props.prevTab()"
                                        :style="'background:#e2e2e2'"
                                    >
                                        {{ $t("previous") }}
                                    </wizard-button>
                                </div>
                                <div class="wizard-footer-right tw-space-x-2">
                                    <!-- <ts-button
                                        v-if="!props.isLastStep"
                                        @click.native="props.nextTab()"
                                        :disabled="nextStep"
                                        color="info"
                                        outline
                                    >
                                        <i
                                            class="fa fa-spinner spin mr-2"
                                            v-if="nextStep"
                                        ></i>
                                        {{ $t("saveTemporarily") }}</ts-button
                                    > -->
                                    <wizard-button
                                        v-if="!props.isLastStep"
                                        @click.native="props.nextTab()"
                                        :style="props.fillButtonStyle"
                                        :disabled="nextStep"
                                    >
                                        <i
                                            class="fa fa-spinner spin mr-2"
                                            v-if="nextStep"
                                        ></i>
                                        {{ $t("next") }}
                                    </wizard-button>
                                    <wizard-button
                                        v-else
                                        @click.native="onComplete"
                                        class="finish-button"
                                        :style="props.fillButtonStyle"
                                        :disabled="waiting"
                                    >
                                        <i
                                            class="fa fa-spinner spin mr-2"
                                            v-if="waiting"
                                        ></i>
                                        {{
                                            props.isLastStep
                                                ? $t("done")
                                                : $t("next")
                                        }}
                                    </wizard-button>
                                </div>
                            </template>
                        </form-wizard>
                    </form>
                    <!-- </ts-panel-wrapper> -->
                </ts-panel>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import ProfileInformation from "./components/profile-information.vue";
import GuarantorTemplate from "./components/guarantor";
import SpouseTemplate from "./components/spouse";
import DocumentAttachment from "./components/document-attachment";
import { Errors } from "form-backend-validation";
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions } from "vuex";

export default {
    name: "customerCreate",
    components: {
        ProfileInformation,
        SpouseTemplate,
        GuarantorTemplate,
        DocumentAttachment,
        FormWizard,
        TabContent,
        WizardButton
    },
    data() {
        return {
            loading: false,
            waiting: false,
            errors: new Errors(),
            nextStep: false,
            clear: false,
            model: {
                customer_name_kh: null,
                customer_name_en: null,
                gender: null,
                marrital_status: null,
                date_of_birth: null,
                contact_no: null,
                email: null,
                photo: null,
                ssn_no: null,
                issue_at: null,
                issued_date: null,
                expiry_date: null,
                house_no: null,
                street_no: null,
                village_code: null,
                commune_code: null,
                district_code: null,
                province_code: null,
                description: null,
                occupation: null,
                guarantor_name_kh: null,
                guarantor_name_en: null,
                guarantor_phone: null,
                relationship: null,
                grt_house_no: null,
                grt_street_no: null,
                grt_village_code: null,
                grt_commune_code: null,
                grt_district_code: null,
                grt_province_code: null,
                grt_doc_type_id:null,
                grt_ssn_no:null,
                grt_issued_date:null,
                grt_expiry_date:null,
                grt_issue_at:null,
                grt_date_of_birth:null,
                doc_type_id: null,
                attach_file_list: [],
                geo_location: null,
                spouse_name_kh: null,
                spouse_name_en: null,
                sp_gender: null,
                sp_date_of_birth: null,
                sp_doc_type_id: null,
                sp_ssn_no: null,
                sp_occupation: null,
                sp_house: null,
                sp_street: null,
                sp_village_code: null,
                sp_commune_code: null,
                sp_district_code: null,
                sp_province_code: null,
                sp_photo: null,
                sp_relationship: null,
                country: null
            }
        };
    },
    mounted() {
        this.$nextTick(function() {
            this.$refs.profileInformation.$refs.customer_name_kh.focus();
        });
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("creditOperation/customer", ["photoUpload"]),
        validateStep(name) {
            var refToValidate = this.$refs[name];
            return refToValidate.validateForm();
        },
        async fetchResource() {
            this.loading = true;
            await this.$refs.profileInformation.getProvince();
            await this.$refs.profileInformation.getDocumentType();
            this.loading = false;
        },
        uploadProfilePhoto() {
            if (
                this.model.photo instanceof File ||
                this.model.photo instanceof Blob
            ) {
                let formData = new FormData();
                formData.append("photo", this.model.photo);
                return this.photoUpload(formData);
            }
            return this.model.photo;
        },
        uploadSpPhoto() {
            if (
                this.model.sp_photo instanceof File ||
                this.model.sp_photo instanceof Blob
            ) {
                let formData = new FormData();
                formData.append("photo", this.model.sp_photo);
                return this.photoUpload(formData);
            }
            return this.model.sp_photo;
        },
        async onComplete() {
            this.errors = new Errors();
            this.waiting = true;
            let photo_path = await this.uploadProfilePhoto();
            let sp_photo_path = await this.uploadSpPhoto();
            await this.$store
                .dispatch(
                    "creditOperation/customer/store",
                    Object.assign({}, this.model, {
                        photo: photo_path,
                        sp_photo: sp_photo_path
                    })
                )
                .then(response => {
                    this.waiting = false;
                    if(response.message_id == 0)
                    {
                        this.clearInput();
                        this.clear = true;
                        this.$refs.wizard.reset();
                        this.notice({
                            type: "success",
                            text: response.message
                        });
                        this.$router.push({ name: "customer" });
                    }
                    if(response.message_id != 0)
                    {
                        this.notice({
                            type: "warning",
                            text: response.message
                        });
                    }
                })
                .catch(error => {
                    this.waiting = false;
                    this.errors = new Errors(error.errors);
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                });
        },
        clearInput() {
            this.model.customer_name_kh = null;
            this.model.customer_name_en = null;
            this.model.gender = null;
            this.model.marrital_status = null;
            this.model.date_of_birth = null;
            this.model.contact_no = null;
            this.model.email = null;
            this.model.photo = null;
            this.model.ssn_no = null;
            this.model.issue_at = null;
            this.model.issued_date = null;
            this.model.expiry_date = null;
            this.model.house_no = null;
            this.model.street_no = null;
            this.model.village_code = null;
            this.model.commune_code = null;
            this.model.district_code = null;
            this.model.province_code = null;
            this.model.description = null;
            this.model.occupation = null;
            this.model.guarantor_name_kh = null;
            this.model.guarantor_name_en = null;
            this.model.guarantor_phone = null;
            this.model.relationship = null;
            this.model.grt_house_no = null;
            this.model.grt_street_no = null;
            this.model.grt_village_code = null;
            this.model.grt_commune_code = null;
            this.model.grt_district_code = null;
            this.model.attach_file_list = [];
            this.model.doc_type_id = null;
            this.model.geo_location = null;
            this.model.grt_province_code = null;
            this.model.grt_doc_type_id = null;
            this.model.grt_ssn_no = null;
            this.model.grt_issued_date = null;
            this.model.grt_expiry_date = null;
            this.model.grt_issue_at = null;
            this.model.grt_date_of_birth = null;
            this.model.spouse_name_kh = null;
            this.model.spouse_name_en = null;
            this.model.sp_gender = null;
            this.model.sp_date_of_birth = null;
            this.model.sp_doc_type_id = null;
            this.model.sp_ssn_no = null;
            this.model.sp_issue_at = null;
            this.model.sp_issued_date = null;
            this.model.sp_expiry_date = null;
            this.model.sp_occupation = null;
            this.model.sp_house = null;
            this.model.sp_street = null;
            this.model.sp_village_code = null;
            this.model.sp_commune_code = null;
            this.model.sp_district_code = null;
            this.model.sp_province_code = null;
            this.model.sp_photo = null;
            this.model.sp_relationship = null;
            this.model.countr = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER PROFILE",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
